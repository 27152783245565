var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("security.panel-settings.settings"))),
        _c(
          "span",
          {
            staticStyle: { float: "right", cursor: "pointer" },
            on: { click: _vm.save }
          },
          [
            _c("i", { staticClass: "fa fa-upload" }),
            _vm._v(" " + _vm._s(_vm.$t("security.panel-settings.save")))
          ]
        )
      ]),
      _c(
        "el-form",
        { attrs: { model: _vm.settings } },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-left": "10px", width: "200px" },
              attrs: { label: _vm.$t("security.panel-settings.exit-delay") }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { type: "number", min: "0" },
                on: { change: _vm.onEntryDelayChanged },
                model: {
                  value: _vm.settings.entryDelay,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "entryDelay", $$v)
                  },
                  expression: "settings.entryDelay"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: {
                label: _vm.$t("security.panel-settings.away-chime-feedback")
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.settings.awayFeedbackActive,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "awayFeedbackActive", $$v)
                  },
                  expression: "settings.awayFeedbackActive"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: {
                label: _vm.$t("security.panel-settings.stay-chime-feedback")
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.settings.stayFeedbackActive,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "stayFeedbackActive", $$v)
                  },
                  expression: "settings.stayFeedbackActive"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: {
                label: _vm.$t(
                  "security.panel-settings.entry-delay-chime-feedback"
                )
              }
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.settings.entryDelayFeedbackActive,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "entryDelayFeedbackActive", $$v)
                  },
                  expression: "settings.entryDelayFeedbackActive"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-left": "10px", width: "200px" },
              attrs: { label: _vm.$t("security.panel-settings.chime-level") }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { type: "number", min: "0", max: "100" },
                on: { change: _vm.onChimeLevelChanged },
                model: {
                  value: _vm.settings.feedbackLevel,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "feedbackLevel", $$v)
                  },
                  expression: "settings.feedbackLevel"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }