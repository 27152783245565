<template>
    <div class="layout-settings">
        <div class="form-title">{{ $t('security.panel-settings.settings') }}<span style="float: right; cursor: pointer"
                @click="save"><i class="fa fa-upload"></i> {{ $t('security.panel-settings.save') }}</span></div>
        <el-form :model="settings">

            <el-form-item :label="$t('security.panel-settings.exit-delay')" style="margin-left: 10px; width: 200px">
                <el-input type="number" min="0" style="width:100px" @change="onEntryDelayChanged"
                    v-model="settings.entryDelay" />
            </el-form-item>

            <el-form-item :label="$t('security.panel-settings.away-chime-feedback')" style="margin-left: 10px;">
                <el-switch v-model="settings.awayFeedbackActive" />
            </el-form-item>

            <el-form-item :label="$t('security.panel-settings.stay-chime-feedback')" style="margin-left: 10px;">
                <el-switch v-model="settings.stayFeedbackActive" />
            </el-form-item>

            <el-form-item :label="$t('security.panel-settings.entry-delay-chime-feedback')" style="margin-left: 10px;">
                <el-switch v-model="settings.entryDelayFeedbackActive" />
            </el-form-item>

            <el-form-item style="margin-left: 10px; width:200px" :label="$t('security.panel-settings.chime-level')">
                <el-input type="number" style="width:100px" @change="onChimeLevelChanged" min="0" max="100"
                    v-model="settings.feedbackLevel" />
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
    name: "Settings",
    data: function () {
        return {
            loading: true
        }
    },

    methods: {
        onChimeLevelChanged(value) {
            if (value < 0)
                this.settings.feedbackLevel = 0
            else if (value > 100)
                this.settings.feedbackLevel = 100
        },
        onEntryDelayChanged(value) {
            if (value < 0)
                this.settings.entryDelay = 0
            else if (value > 100)
                this.settings.entryDelay = 100
        },
        save() {
            this.loading = true;
            this.$store.dispatch('saveSecurityPanelSettings', this.$store.getters.securityPanelSettings).then(response => {
                this.loading = false;
                this.$message({
                    message: this.$t(response.message),
                    type: 'success'
                })
            }).catch(reason => {
                this.loading = false;
                this.$message({
                    message: this.$t(reason),
                    type: 'error'
                })
            })
        },
        async fetchData() {
            try {
                await this.$store.dispatch("getSecurityPanelSettings");
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        }
    },

    computed: {
        settings: function () {
            return this.$store.getters.securityPanelSettings
        },
        isConnected() {
            return this.$store.getters.isConnected;
        },
    },
    watch: {
        isConnected(value) {
            if (value) {
                this.fetchData();
            }
        }
    },

    created() {
        if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
    }
}
</script>