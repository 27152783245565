<template>
  <div>
  <el-row>
      <el-col>
        <app-navigation :title="$t('security')"></app-navigation>
      </el-col>
    </el-row>
    <div class="security">
        <settings/>
        <arming-settings/>
    </div>
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation';
  import ArmingSettings from "./components/ArmingSettings"
  import Settings from './components/Settings';

  export default {
    name: "PanelSettings",

    components: {
      appNavigation: Navigation,
      armingSettings: ArmingSettings,
      settings: Settings
    },

    data: function () {
      return {
        }
    }
  }
  </script>

  <style>
    @import "../../../style/security.css";
.layout-settings {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fff;
}
.layout-settings:hover {
  box-shadow: 0 0 15px #ccc;
}
  </style>