var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("security.panel-settings.arming-settings"))),
        _c(
          "span",
          {
            staticStyle: { float: "right", cursor: "pointer" },
            on: { click: _vm.save }
          },
          [
            _c("i", { staticClass: "fa fa-upload" }),
            _vm._v(" " + _vm._s(_vm.$t("security.panel-settings.save")))
          ]
        )
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "arming-settings",
          attrs: { data: _vm.$store.getters.armingSettings }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "icon",
              label: _vm.$t("security.panel-settings.icon"),
              "default-sort": { prop: "name", order: "ascending" },
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticClass: "icon-contact",
                      attrs: {
                        src: "/assets/icons/" + scope.row.icon + ".png"
                      },
                      on: { error: _vm.onImageError }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "min-width": "150",
              label: _vm.$t("security.panel-settings.name"),
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "category",
              "min-width": "150",
              label: _vm.$t("security.panel-settings.category")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(_vm.categoryName(scope.row.category))) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "armStateInStayMode",
              "min-width": "150",
              label: _vm.$t("security.panel-settings.armed-stay-mode")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: { disabled: scope.row.armStateInDisarmMode },
                      model: {
                        value: scope.row.armStateInStayMode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "armStateInStayMode", $$v)
                        },
                        expression: "scope.row.armStateInStayMode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "armStateInAwayMode",
              "min-width": "150",
              label: _vm.$t("security.panel-settings.armed-away-mode")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-checkbox", {
                      attrs: { disabled: scope.row.armStateInDisarmMode },
                      model: {
                        value: scope.row.armStateInAwayMode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "armStateInAwayMode", $$v)
                        },
                        expression: "scope.row.armStateInAwayMode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "armStateInDisarmMode",
              "min-width": "150",
              label: _vm.$t("security.panel-settings.armed-724-mode")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-checkbox", {
                      on: {
                        change: function($event) {
                          return _vm.onChange($event, scope.row)
                        }
                      },
                      model: {
                        value: scope.row.armStateInDisarmMode,
                        callback: function($$v) {
                          _vm.$set(scope.row, "armStateInDisarmMode", $$v)
                        },
                        expression: "scope.row.armStateInDisarmMode"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "entryDelay",
              "min-width": "150",
              label: _vm.$t("security.panel-settings.entry-delay")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input-number", {
                      attrs: { disabled: scope.row.armStateInDisarmMode },
                      model: {
                        value: scope.row.entryDelay,
                        callback: function($$v) {
                          _vm.$set(scope.row, "entryDelay", $$v)
                        },
                        expression: "scope.row.entryDelay"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }