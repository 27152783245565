var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [_c("app-navigation", { attrs: { title: _vm.$t("security") } })],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "security" },
        [_c("settings"), _c("arming-settings")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }