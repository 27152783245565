<template>
  <div class="layout-settings">
    <div class="form-title">{{ $t('security.panel-settings.arming-settings') }}<span style="float: right; cursor: pointer"
        @click="save"><i class="fa fa-upload"></i> {{ $t('security.panel-settings.save') }}</span></div>
    <el-table class="arming-settings" :data="$store.getters.armingSettings" v-loading="loading">
      <el-table-column prop="icon" :label="$t('security.panel-settings.icon')"
        :default-sort="{ prop: 'name', order: 'ascending' }" width="100">

        <template slot-scope="scope">
          <img @error="onImageError" :src="'/assets/icons/' + scope.row.icon + '.png'" class="icon-contact">
        </template>

      </el-table-column>

      <el-table-column prop="name" min-width="150" :label="$t('security.panel-settings.name')" sortable>
      </el-table-column>

      <el-table-column prop="category" min-width="150" :label="$t('security.panel-settings.category')">
        <template slot-scope="scope">
          {{ $t(categoryName(scope.row.category)) }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="armStateInStayMode" min-width="150"
        :label="$t('security.panel-settings.armed-stay-mode')">
        <template slot-scope="scope">
          <el-checkbox :disabled="scope.row.armStateInDisarmMode" v-model="scope.row.armStateInStayMode" />
        </template>
      </el-table-column>

      <el-table-column align="center" prop="armStateInAwayMode" min-width="150"
        :label="$t('security.panel-settings.armed-away-mode')">
        <template slot-scope="scope">
          <el-checkbox :disabled="scope.row.armStateInDisarmMode" v-model="scope.row.armStateInAwayMode" />
        </template>
      </el-table-column>

      <el-table-column align="center" prop="armStateInDisarmMode" min-width="150"
        :label="$t('security.panel-settings.armed-724-mode')">
        <template slot-scope="scope">
          <el-checkbox @change="onChange($event, scope.row)" v-model="scope.row.armStateInDisarmMode" />
        </template>
      </el-table-column>

      <el-table-column align="center" prop="entryDelay" min-width="150"
        :label="$t('security.panel-settings.entry-delay')">
        <template slot-scope="scope">
          <el-input-number :disabled="scope.row.armStateInDisarmMode" v-model="scope.row.entryDelay" />
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>

import Navigation from '@/components/Navigation';
import { categoryName } from "@/utils/accessory-utils";
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "ArmingSettings",

  components: {
    'app-navigation': Navigation
  },

  data: function () {
    return {
      loading: true,
      checksEnables: true
    }
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    categoryName,
    onImageError: function (e) {
      e.target.src = "/assets/icons/ic_switch_onoff.png";
    },
    onChange: function (e, row) {
      row.armStateInAwayMode = e;
      row.armStateInStayMode = e;
      if (e) {
        row.entryDelay = 0;
      }
    },
    save() {
      this.loading = true;
      this.$store.dispatch('saveArmingSettings', this.$store.getters.armingSettings).then(response => {
        this.loading = false;
        this.$message({
          message: this.$t(response.message),
          type: 'success'
        })
      }).catch(reason => {
        this.loading = false;
        this.$message({
          message: this.$t(reason),
          type: 'error'
        })
      })
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getArmingSettings");
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}

</script>

<style scoped>
.icon-contact {
  filter: brightness(0.45);
  width: 40px;
  height: 40px;
}
</style>